import { NumericPipe } from './../core/pipes/numeric.pipe';
import { RadioGroupComponent } from './components/control-elements/radio-group/radio-group.component';
import { FileUploadComponent } from './components/control-elements/file-upload/file-upload.component';
import { DynamicComponent } from './components/container-elements/dynamic/dynamic.component';
import { TextComponent } from './components/control-elements/text/text.component';
import { CheckboxGroupComponent } from './components/control-elements/checkbox-group/checkbox-group.component';
import { SectionTableComponent } from './components/container-elements/table/table.component';
import { ButtonComponent } from './components/control-elements/button/button.component';
import { CardComponent } from './components/container-elements/card/card.component';
import { ControlsFabricComponent } from './components/controls-fabric/controls-fabric.component';
import { ContainersFabricComponent } from './components/containers-fabric/containers-fabric.component';
import { SectionComponent } from './components/container-elements/section/section.component';
import { FormComponent } from './components/layout-elements/layout/form.component';
import { TextAreaComponent } from './components/control-elements/text-area/text-area.component';
import { SelectComponent } from './components/control-elements/select/select.component';
import { InputComponent } from './components/control-elements/input/input.component';
import { SearchComponent } from './components/control-elements/search/search.component'
import { MultiSelectComponent } from './components/control-elements/multi-select/multi-select.component'
import { FormBuilderComponent } from './form-builder.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { from } from 'rxjs';

import { cardRightDynamicComponent } from './components/control-dynamic-elements/card-right/card-right.component'
import { cardTopDynamicComponent } from './components/control-dynamic-elements/card-top/card-top.component'
import { tableDynamicComponent } from './components/control-dynamic-elements/table/table.component'
import { tableFormDynamicComponent } from './components/control-dynamic-elements/table-form/table-form.component'
import { tableTopDynamicComponent } from './components/control-dynamic-elements/table-top/table-top.component'
import { tableTopHallazgosDynamicComponent } from './components/control-dynamic-elements/table-top-hallazgos/table-top-hallazgos.component'
import { tableTopReplyDynamicComponent } from './components/control-dynamic-elements/table-top-reply/table-top-reply.component'
import { tableTopSubstancesDynamicComponent } from './components/control-dynamic-elements/table-top-substances/table-top-substances.component'

@NgModule({
  declarations: [
    FormBuilderComponent, 
    FormComponent, 
    InputComponent, 
    SelectComponent, 
    TextAreaComponent,  
    SectionComponent, 
    ContainersFabricComponent, 
    ControlsFabricComponent, 
    CardComponent, 
    ButtonComponent, 
    SectionTableComponent, 
    CheckboxGroupComponent, 
    TextComponent, 
    DynamicComponent, 
    FileUploadComponent, 
    RadioGroupComponent,
    SearchComponent,
    MultiSelectComponent,

    cardRightDynamicComponent,
    cardTopDynamicComponent,
    tableDynamicComponent,
    tableFormDynamicComponent,
    tableTopDynamicComponent,
    tableTopHallazgosDynamicComponent,
    tableTopReplyDynamicComponent,
    tableTopSubstancesDynamicComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger'
    }),
    NgbModule,
    NgSelectModule
  ],
  exports: [FormBuilderComponent]
})
export class FormBuilderModule { }
