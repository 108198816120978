<div class="row">
    <div class="col-12">
        <div class="form-group">
            <h3>{{attributes.title}}</h3>
            <h5 *ngIf="attributes.subtitle">{{attributes.subtitle}}</h5>
        </div>
    </div>
    <div class="col-12">
        <table class="table table-bordered" [ngStyle]="{'table-layout': attributes.fixedSize ? 'fixed' : 'auto'}">
            <thead class="thead-dark">
                <tr>
                    <th *ngIf="attributes.numbered">#</th>
                    <th scope="col" *ngFor="let item of attributes.header; let i = index" [ngStyle]="{'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}" style="line-break: anywhere;">{{item}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.content; let i = index">
                    <td *ngIf="attributes.numbered">
                        {{i+1}}
                    </td>
                    <td 
                        *ngFor="let itemx of item.content"
                        [style.border]="itemx.hide ? '0px solid #dee2e6' : ''"
                        [ngSwitch]="itemx.type" 
                        [style.width.%]="(itemx.type==='text' || itemx.type==='section') && i===0 && attributes.numbered ? 50 : ''"
                        [attr.colspan]="getColspan(item)"
                    >
                        <input-element *ngSwitchCase="'input'" [data]="itemx" [form]="form"></input-element>
                        <select-element *ngSwitchCase="'select'" [data]="itemx" [form]="form"></select-element>
                        <button-element *ngSwitchCase="'button'" [data]="itemx" [form]="form"></button-element>
                        <checkbox-group-element *ngSwitchCase="'checkbox'" [data]="itemx" [form]="form"></checkbox-group-element>
                        <radio-group-element *ngSwitchCase="'radio'" [data]="itemx" [form]="form"></radio-group-element>
                        <text-element *ngSwitchCase="'text'" [data]="itemx"></text-element>
                        <textarea-element *ngSwitchCase="'textarea'" [data]="itemx" [form]="form"></textarea-element>
                        <file-upload-element *ngSwitchCase="'fileupload'" [data]="itemx" [form]="form"></file-upload-element>
                        <section-container *ngSwitchCase="'section'" [data]="itemx" [form]="form"></section-container>
                        <table-container *ngSwitchCase="'table'" [data]="itemx" [form]="form"></table-container>
                        <dynamic-container *ngSwitchCase="'dynamic'" [data]="itemx" [form]="form"></dynamic-container>
                        <search-element *ngSwitchCase="'search'" [data]="item" [form]="formGroup"></search-element>
                        <multiselect-element *ngSwitchCase="'multiselect'" [data]="itemx" [form]="formGroup"></multiselect-element>
                        <div *ngSwitchDefault>UNK</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>