import { ModalTomaMuestras } from './core/components/modal-toma-muestras/modal-toma-muestras.component';
import { ModalNoConformidad } from './core/components/modal-no-conformidad/modal-no-conformidad.component';
import { SocketsService } from './core/services/sockets.service';
import { CoreModule } from './core/core.module';
import { FormStepper } from '@app/core/components/form-stepper';
import { ControlCommunicationService } from './form-builder/services/control-communication.service';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormBuilderModule } from './form-builder/form-builder.module';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {DatePipe} from '@angular/common'

// Used to create fake backend
//import { fakeBackendProvider } from './core/helpers';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor, ErrorInterceptor, appInitializer, ResponseInterceptor } from './core/helpers';
import { AuthenticationService } from "@app/core/services";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from './core/components/alert';
import { FooterComponent } from './core/components/footer';
import { HomeComponent } from './features/home';
import { LoginComponent } from "./features/login";
import { ForgotPasswordComponent } from "./features/forgot-password";
import { ForgotPasswordConfirmationComponent } from "./features/forgot-password-confirmation";
import { ResetPasswordComponent } from "./features/reset-password";
import { DashboardItemComponent } from "./core/components/dashboard-item";
import { ModalChangePassword } from "./core/components/change-password";
import { ModalChangeProfileImage } from "./core/components/change-imageProfile"
import { SettingsComponent } from "./features/settings";

import { ModalContract } from "./core/components/modal-contract";
import { ContractMexico, ContractCanada, ContractEurope } from '@app/core/components/contracts/V3'

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

import { NgxUploaderModule } from 'ngx-uploader';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'

import { ModalChangeReviewer } from "./core/components/modal-changeReviewer";
import { ModalChangeInspector } from "./core/components/modal-changeInspector";
import { ModalChangeDictator } from "./core/components/modal-changeDictator";
import { ModalInstructions } from "./core/components/modal-instructions";
import { ModalPreview } from "./core/components/modal-preview";
import { ModalSelectCustomer } from './core/components/modal-selectCustomer';
import { ModalChangeReviewerSubstances } from './core/components/modal-changeReviewerSubstances';
import { ModalNcsDocuments } from './core/components/modal-ncs-documents'

import { 
    ModalConfirmAutofocus, 
    ModalSign, 
    ModalApprove, 
    ModalComplete, 
    ModalDelete, 
    ModalApproveInspection, 
    ModalDenyInspection, 
    ModalUploadOfflineData, 
    ModalApproveEvaluation, 
    ModalDenyEvaluation, 
    ModalApproveSubstances, 
    ModalDenyComments, 
    ModalConfirmGenerateCertificate, 
    ModalConfirmUpdateLaboratory,
    ModalUserActions,
    ModalRestorePassword,
    ModalInformGeneratingReport,
    ModalApproveInspectionNotAnnounced,
    ModalApproveEvaluationNotAnnounced,
    ModalConfirmCancelCustomer,
    ModalCancelRecord
} from "./core/components/modal-confirm";

registerLocaleData(localeEs, 'es');

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormBuilderModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        NgxUploaderModule,
        NgxDocViewerModule,
        FontAwesomeModule,
        CoreModule,
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        FooterComponent,
        DashboardItemComponent,
        ForgotPasswordComponent,
        ForgotPasswordConfirmationComponent,
        ResetPasswordComponent,
        ModalConfirmAutofocus,
        SettingsComponent,
        ModalChangePassword,
        ModalChangeProfileImage,
        ModalSign,
        ModalApprove,
        ModalComplete,
        ModalContract,
        ModalDelete,
        ModalApproveInspection,
        ModalDenyInspection,
        ModalDenyComments,
        ModalUploadOfflineData,
        ModalApproveEvaluation,
        ModalDenyEvaluation,
        ModalApproveSubstances,
        ModalInstructions,
        ModalSelectCustomer,
        ModalConfirmGenerateCertificate,
        ModalConfirmUpdateLaboratory,
        ModalUserActions,
        ModalRestorePassword,
        ModalApproveInspectionNotAnnounced,
        ModalApproveEvaluationNotAnnounced,
        ModalCancelRecord,
        ModalConfirmCancelCustomer,
        // Modal Contracts Start
        ContractMexico,
        ContractCanada,
        ContractEurope,
        // Modal Contracts End
        ModalChangeReviewer,
        ModalChangeInspector,
        ModalChangeDictator,
        ModalChangeReviewerSubstances,
        ModalPreview,
        ModalNoConformidad,
        ModalTomaMuestras,
        ModalInformGeneratingReport,
        ModalNcsDocuments,
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: DatePipe },
        FormBuilderComponent,
        ControlCommunicationService,
        SocketsService
        // Provider used to create fake backend
        //fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };
