import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FormBuilderComponent } from './../../../form-builder.component';
import { UnknownParams } from './../../../interfaces/form.interface';
import { ControlElement } from '../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

@Pipe({
    name: 'numberFormatt'
})
export class NumericPipe {
    transformFloat(val: any, ...args: any[]) {
        //console.log("pipe", val, args)
        const format = args[0] ? '1.4-4' : '1.4-4'; 
        return this.decimalPipe.transform(val, format, 'en-US');
    }

    transformInt(val: any, ...args: any[]) {
        //console.log("pipe", val, args)
        const format = args[0] ? '1.0-0' : '1.0-0'; 
        return this.decimalPipe.transform(val, format, 'en-US');
    }

    constructor(private decimalPipe: DecimalPipe) { }
}

@Component({
    selector: 'input-element',
    templateUrl: './input.component.html',
    providers: [NumericPipe]
})
export class InputComponent implements OnInit {
    minDate: String = moment().format("YYYY-MM-DD")
    maxDate: String = moment().format("YYYY-MM-DD")

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() form: FormGroup

    @Input() data: ControlElement
    
    // inject parent FormBuilder Component in order to access FormGroup
    constructor(
        private parentControl: FormBuilderComponent, 
        private pipe: NumericPipe, 
        private communicationService: ControlCommunicationService,
    ) {
        this.communicationService.changeEmmited$.subscribe(msg => {
			if (msg.identifier === 'submit') {
                if(!this.attributes.coordinate) {
                    if (this.attributes.type === 'number') {
                        var val: string = this.form.get(this.attributes.name).value
                        val = val !== null ? "" + val : ''

                        const valueTemp = val.includes(',') ? val.split(",").join("") : val
                        const numberValue = isNaN(parseFloat(valueTemp)) ? 0 : parseFloat(valueTemp)

                        this.form.get(this.attributes.name).patchValue(numberValue)
                    } else if(this.attributes.type === 'number_integer') {
                        var val: string = this.form.get(this.attributes.name).value
                        val = val !== null ? "" + val : ''

                        const valueTemp = val.includes(',') ? val.split(",").join("") : val
                        const numberValue = isNaN(parseInt(valueTemp)) ? 0 : parseInt(valueTemp)

                        this.form.get(this.attributes.name).patchValue(numberValue)
                    }
                } else if(this.attributes.coordinate) {
                    var val: string = this.form.get(this.attributes.name).value
                    val = val !== null ? val : '0'
                    val = isNaN(parseInt(val)) ? '0' : val

                    //console.log(val, typeof val)

                    this.form.get(this.attributes.name).patchValue(val)
                }
			} else if(msg.identifier === 'date-ranges') {
                this.minDate = moment(msg.form['desde']).format("YYYY-MM-DD")
                this.maxDate = moment(msg.form['hasta']).format("YYYY-MM-DD")
            }
		})
    }

    update() {
        if(!this.attributes.coordinate) {
            if (this.attributes.type === 'number') {
                //console.log("form", this.form, this.attributes.name, this.form.get(this.attributes.name).value)

                const value = this.form.get(this.attributes.name).value

                var val
                if(typeof value === 'string') {
                    const valueTemp = value.includes(',') ? value.split(",").join("") : value
                    const parseValue = isNaN(parseFloat(valueTemp)) ? 0 : parseFloat(valueTemp)
                    val = this.pipe.transformFloat(parseValue, parseValue > 100)
                } else {
                    val = this.pipe.transformFloat(value, value > 100)
                }

                val = this.validations.disabled && parseFloat(val) === 0 
                        ? 0 
                        : parseFloat(val) === 0 
                            ? '' 
                            : val

                this.form.get(this.attributes.name).patchValue(val)


                /* let val
                console.log("form", this.form, this.attributes.name)
                if (!isNaN(this.form.get(this.attributes.name).value)) {
                    const value = "" + this.form.get(this.attributes.name).value
                    const valueTemp = value.includes(',') ? value.split(",").join("") : value
                    const parseValue = parseFloat(valueTemp)

                    console.log("valores", val, value, valueTemp, parseValue)

                    val = this.pipe.transform("" + parseValue, parseValue > 100)
                } else {
                    val = ""
                }
                this.form.get(this.attributes.name).patchValue(val) */
            } else if(this.attributes.type === 'number_integer') {
                const value = this.form.get(this.attributes.name).value

                var val
                if(typeof value === 'string') {
                    const valueTemp = value.includes(',') ? value.split(",").join("") : value
                    const parseValue = parseInt(valueTemp)
                    val = this.pipe.transformInt(parseValue, parseValue > 100)
                } else {
                    val = this.pipe.transformInt(value, value > 100)
                }

                val = parseInt(val) === 0 ? '' : val

                this.form.get(this.attributes.name).patchValue(val)
            }
        }
    }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        if (!this.form) {this.form = this.parentControl.formGroup}
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
        if (this.attributes.conditional_name) {
            for (var i in this.attributes.conditional_name) {
                const name = this.attributes.conditional_name[i]
                this.form.addControl(name, new FormControl(this.attributes.default ? this.attributes.default : ''))
            }
        } else {
            this.form.addControl(this.attributes.name, new FormControl(this.attributes.default ? this.attributes.default : ''))
        }

        this.update()
    }


}
